import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import NavSection from './NavSection.component';
import { Section } from '../../../types/server-data/ClientConfig';
import { clientSectionsSelector } from '../../../redux/pages/selectors';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  navContainerRoot: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
});

const NavContainer: React.FC = (): ReactElement => {
  const classes = useStyles();

  const clientSections = useSelector(clientSectionsSelector) || [];

  console.log('clientSections', clientSections);

  return (
    <div className={classes.navContainerRoot}>
      {clientSections.map((section: Section) => {
        return <NavSection key={section.sectionId} section={section} />;
      })}
    </div>
  );
};

export default NavContainer;
