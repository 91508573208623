import React, { ReactElement, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { scaleLinear } from 'd3-scale';
import json from './map.json';
import { RaptorTheme, mainColors } from '../../../styling/theme';
import { useWindowSize } from '../../../hooks/useWindowSize';
import new_map_json from './new_map.json';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';

export const useOverviewDetailStyles = makeStyles<Theme>((theme) => {
  return {
    chartContainerDiv: {
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    map: {
      textAlign: 'left',
      // margin: '1.5rem 0',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    calendar: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      marginTop: '1.5rem',
      height: '25rem',
      width: '100%',
    },
    chart: {
      // NOTE this could be better - DRY
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      marginTop: '1.5rem',
    },
    outerDiv: {
      textAlign: 'center',
      // height: "40rem",
      width: '80%',
      maxWidth: '60rem',
      height: '80%',
    },
    container: {
      position: 'relative',
      // height: "50rem",
      padding: '2rem 0',
      paddingBottom: 0,
    },
    mapTitle: {
      marginTop: 0,
      marginBottom: '3rem',
      color: theme.palette.primary.main,
      textAlign: 'center',
    },
    lineChart: {
      transform: 'translateX(2.5rem)',
      '& g:first-child': {
        backgroundColor: 'red',
      },
    },
    colorChartBox: {
      height: 100,
      width: 100,
      position: 'absolute',
      top: 10,
      left: 10,
      backgroundColor: 'red',
    },
    header: {
      width: '100%',
      borderBottom: '1px solid gray',
      fontSize: '18px',
      textAlign: 'center',
      padding: '5px',
    },
    content: {
      width: '100rem',
      padding: '10px 5px',
      background: 'orange',
    },
    actions: {
      width: '100%',
      padding: '10px 5px',
      margin: 'auto',
      textAlign: 'center',
    },
    close: {
      cursor: 'pointer',
      position: 'absolute',
      display: 'block',
      padding: '2px 5px',
      lineHeight: '20px',
      right: '-10px',
      top: '-10px',
      fontSize: '24px',
      background: '#ffffff',
      borderRadius: '18px',
      border: '1x solid #cfcece',
    },
  };
});

interface GenericMapProps {
  // val: any;
  data: any;
  title?: string;
  tooltipLabelFormatter: (val: string | number) => string;
  tooltipValueFormatter: (val: string | number) => string | number;
  color?: string;
}

const useMapStyles = makeStyles<Theme>((theme) => {
  return {
    mapTitle: {
      color: theme.palette.primary.main,
      fontWeight: 400,
      fontSize: '2.4rem',
      textAlign: 'center',
      margin: 0,
    },
  };
});

export function GenericMapChart(props: GenericMapProps): ReactElement {
  const { data } = props;
  const dict: any = {};
  let maxVal = 0;
  data.forEach((item: any) => {
    dict[item[0]] = item[1];
    if (item[1] > maxVal) {
      maxVal = item[1];
    }
  });
  const [content, setContent] = useState('');
  maxVal = maxVal > 0 ? maxVal : 1;
  const mapColor = props.color ? props.color : mainColors.mainBlue;
  const exposureScale = scaleLinear()
    .domain([0, maxVal])
    .range(['white' as unknown as number, mapColor as unknown as number]);

  const { height } = useWindowSize();
  const classes = useMapStyles();
  const handleLeave = () => {
    setContent('');
  };

  return (
    <div style={{ display: 'grid', height: '100%' }}>
      {props.title && <h2 className={classes.mapTitle}>{props.title}</h2>}
      <ComposableMap
        data-tip=""
        viewBox={'-50 250 1000 800'}
        height={1000}
        width={800}
      >
        <Geographies geography={json}>
          {({ geographies, projection }) => {
            return geographies.map((geography, i) => {
              if (dict[geography.properties['Alpha-2']] === undefined) {
                dict[geography.properties['Alpha-2']] = 0;
              }

              return (
                <Geography
                  key={geography.properties.name}
                  geography={geography}
                  onMouseEnter={(e) => {
                    setContent(
                      `${props.tooltipValueFormatter(
                        dict[geography.properties['Alpha-2']]
                      )} - ${props.tooltipLabelFormatter(
                        geography.properties.name
                      )}`
                    );
                  }}
                  onMouseLeave={handleLeave}
                  style={{
                    default: {
                      fill: exposureScale(
                        dict[geography.properties['Alpha-2']]
                      ) as unknown as string,
                      stroke: '#607D8B',
                      strokeWidth: 0.75,
                      outline: 'none',
                    },
                    hover: {
                      fill: exposureScale(
                        dict[geography.properties['Alpha-2']]
                      ) as unknown as string,
                      stroke: '#777',
                      strokeWidth: 1.5,
                      outline: 'none',
                    },
                    pressed: {
                      outline: 'none',
                      fill: exposureScale(
                        dict[geography.properties['Alpha-2']]
                      ) as unknown as string,
                      stroke: '#777',
                      strokeWidth: 1.5,
                    },
                  }}
                />
              );
            });
          }}
        </Geographies>
      </ComposableMap>
      <ReactTooltip backgroundColor={mainColors.mainBlue} className="tooltip">
        {content}
      </ReactTooltip>
    </div>
  );
}

export function GenericMapChart2(props: GenericMapProps): ReactElement {
  const { data } = props;
  const dict: any = {};
  let maxVal = 0;
  data.forEach((item: any) => {
    dict[item[0]] = item[1];
    if (item[1] > maxVal) {
      maxVal = item[1];
    }
  });
  const [content, setContent] = useState('');
  maxVal = maxVal > 0 ? maxVal : 1;
  const mapColor = props.color ? props.color : mainColors.mainBlue;
  const exposureScale = scaleLinear()
    .domain([0, maxVal])
    .range(['white' as unknown as number, mapColor as unknown as number]);

  const { height } = useWindowSize();
  const { classes } = useMapStyles();
  const handleLeave = () => {
    setContent('');
  };

  return (
    <div style={{ display: 'grid', height: '100%', maxHeight: '80vh' }}>
      <svg
        version="1.1"
        id="world_map_chart"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 2600 900"
        xmlSpace="preserve"
        height="857"
        width="2000"
      >
        <defs>
          <clipPath id="cut-off-esg">
            <rect x="0" y="0" width="156" height={100} />
          </clipPath>
        </defs>
        {new_map_json.map((value, index) => {
          return (
            <path
              stroke="black"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".2"
              fill="#ececec"
              d={value.path}
              id={value.id}
              key={`path_${index}`}
              onMouseEnter={(e) => {
                setContent(
                  `${props.tooltipValueFormatter(
                    dict[value.alpha2]
                  )} - ${props.tooltipLabelFormatter(value.countryName)}`
                );
              }}
              onMouseLeave={handleLeave}
            />
          );
        })}
      </svg>
      <ReactTooltip backgroundColor={mainColors.mainBlue} className="tooltip">
        {content}
      </ReactTooltip>
    </div>
  );
}

export default GenericMapChart;
