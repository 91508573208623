import React from 'react';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import IndicatorComplianceTable from './IndicatorComplianceTable.component';
import PreTradeChecker from './PreTradeChecker.component';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import useFetchData from '../../../../hooks/useFetchData';

export interface IndicatorComplianceUnderlyingTableData {
  position_name: string;
  involvement: string;
  indicator: string;
  value: number;
  pass_fail: string;
  position_id: string;
  gmid: string;
  is_edited: boolean;
}

export interface IndicatorComplianceTableData {
  indicator: string;
  name: string;
  description: string;
  limit_type: string;
  limit: number;
  involvement: string;
  value: number | string;
  pass_fail: string;
  unit: string;
  underlying: IndicatorComplianceUnderlyingTableData[];
}

const IndicatorCompliance: React.FC<FundInfoComponentProps> = ({
  fundId,
  positionDate,
}) => {
  const indicatorComplianceData = useFetchData({
    url: `indicator_compliance/${fundId}/${positionDate}`,
    keyName: `indicator_compliance_${fundId}_${positionDate}`,
    makeFetch: fundId && positionDate ? true : false,
  });

  console.log('indicatorComplianceData', indicatorComplianceData);

  return (
    <GeneralComponentErrorShield
      dataObjects={[indicatorComplianceData]}
      customLoadingMessages={['Loading Indicator Compliance Data']}
      customErrorMessage="No Data Available."
    >
      <IndicatorComplianceTable
        positionDate={positionDate}
        fundId={fundId}
        data={indicatorComplianceData}
      />
      {indicatorComplianceData &&
      indicatorComplianceData.data[0] &&
      indicatorComplianceData.data[0].configs.pre_trade_check === true ? (
        <PreTradeChecker fundId={fundId} positionDate={positionDate} />
      ) : null}
    </GeneralComponentErrorShield>
  );
};

export default IndicatorCompliance;
