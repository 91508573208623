import React, { useEffect } from 'react';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import useTitleBarInput from '../../../../hooks/useTitleBarInput';
import useFetchData from '../../../../hooks/useFetchData';
import MarketOverviewTable from './subComponents/MarketOverviewTable.component';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { useDispatch } from 'react-redux';
import {
  addAdditionalTitleBarComponents,
  removeTitleBarComponent,
} from '../../../../redux/pages/actions';
import {
  APPEND_NEW_INPUT_TO_TITLEBAR,
  APPEND_NEW_SELECT_TO_TITLEBAR,
  REMOVE_INPUT_FROM_TITLEBAR,
  REMOVE_SELECT_FROM_TITLEBAR,
} from '../../../../types/redux/pages/pageTypes';
import useTitleBarSelect from '../../../../hooks/useTitleBarSelect';
import { Input, Select } from '../../../../types/redux/pages/PagesStore';

const MarketOverview: React.FC<GeneralOverviewPageRenderedComponentProps> = ({
  positionDate,
}) => {
  const dispatch = useDispatch();

  // Add the select and input components to the title bar
  useEffect(() => {
    const selectsToAdd: Select[] = [
      {
        displayOnOverviewPage: true,
        titleBarKey: 'selectMarketOverviewType',
        titleBarTitle: 'Type: ',
        values: [
          {
            label: 'Volatility',
            value: 'vol',
          },
          {
            label: 'VaR',
            value: 'var',
          },
        ],
        aValueIsAlwaysSelected: true,
        currentValue: 'vol',
      },
    ];

    dispatch(
      addAdditionalTitleBarComponents(
        selectsToAdd,
        APPEND_NEW_SELECT_TO_TITLEBAR
      )
    );

    const inputsToAdd: Input[] = [
      {
        displayOnOverviewPage: true,
        titleBarKey: 'marketOverviewLookbackDays',
        titleBarTitle: 'Lookback Days: ',
        currentValue: '1',
        defaultValue: '1',
        allowedValues: [...Array(1000).keys()].map((i) => String(i + 1)),
      },
    ];

    dispatch(
      addAdditionalTitleBarComponents(inputsToAdd, APPEND_NEW_INPUT_TO_TITLEBAR)
    );

    return () => {
      dispatch(
        removeTitleBarComponent(
          'selectMarketOverviewType',
          REMOVE_SELECT_FROM_TITLEBAR
        )
      );
      dispatch(
        removeTitleBarComponent(
          'marketOverviewLookbackDays',
          REMOVE_INPUT_FROM_TITLEBAR
        )
      );
    };
  }, []);

  const type = useTitleBarSelect('selectMarketOverviewType') || 'vol';
  const lookbackDays = useTitleBarInput('marketOverviewLookbackDays');

  const marketOverviewData = useFetchData({
    url: `/market_overview/${positionDate}/${lookbackDays}`,
    keyName: `market_overview_${positionDate}_${lookbackDays}`,
    makeFetch: lookbackDays !== undefined,
  });

  console.log(positionDate, lookbackDays, type);

  return (
    <GeneralComponentErrorShield
      dataObjects={[marketOverviewData]}
      customLoadingMessages={['Loading Market Overview Data...']}
      customErrorMessage="No Data Available."
    >
      <MarketOverviewTable data={marketOverviewData} type={type} />
    </GeneralComponentErrorShield>
  );
};

export default MarketOverview;
